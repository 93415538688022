import { createContext, ReactNode, useContext, useState } from 'react';

import { SignedInUser } from '@/typescript/interfaces';

export interface AppState {
  user: SignedInUser;
  company: string; // for switch companies
  setCompany: (value: string | '') => void;
}

export const AppContext = createContext<AppState>({
  user: {} as SignedInUser,
  company: '', // for switch companies
  setCompany: (value: string) => '',
});

export const AppContextProvider = ({
  children,
  user,
}: AppContextProviderProps) => {
  // Store global state here.
  const [company, setCompany] = useState<string>(
    user.signInUserSession?.idToken.payload['custom:company'] || ''
  ); //default company

  return (
    <AppContext.Provider value={{ user, company, setCompany }}>
      {children}
    </AppContext.Provider>
  );
};

interface AppContextProviderProps {
  children: ReactNode;
  user: SignedInUser;
}

/**
 * Custom hook for accessing global app state.
 * @returns AppState
 */
export const useStore = () => useContext(AppContext);
