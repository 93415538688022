import { FC, Suspense, lazy, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Box } from '@mui/material';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import toast, { Toaster } from 'react-hot-toast';

import { AppContextProvider } from '@/hooks/useStore';
import AppScopeLoadingComponent from '@/components/AppScopeLoading';
import { errorHandler } from '@/utils/errorHandler';
import { SignedInUser } from '@/typescript/interfaces';

const AppRouter = lazy(() => import('./Router/router'));

// Create a query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error, { meta }) => {
      errorHandler(error as Error);
      // Show error message
      if (meta?.errorMessage) {
        toast.error(meta?.errorMessage as string);
      }
    },
    onSuccess: (_data, { meta }) => {
      // Show success message
      if (meta?.successMessage) {
        toast.success(meta?.successMessage as string);
      }
    },
  }),
  mutationCache: new MutationCache({
    onError: (error, _variables, _context, mutation) => {
      errorHandler(error as Error);
      // Show error message
      if (mutation.meta?.errorMessage) {
        toast.error(mutation.meta?.errorMessage as string);
      }
    },
    onSuccess: (_data, _variables, _context, mutation) => {
      // Show success message
      if (mutation.meta?.successMessage) {
        toast.success(mutation.meta?.successMessage as string);
      }
    },
  }),
});

const App: FC<Props> = ({ user, signOut }): JSX.Element => {
  // High level state to control the Side Drawer via sibbling Navbar
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <AppContextProvider user={user}>
          <Box sx={{ display: 'flex' }}>
            <Suspense fallback={<AppScopeLoadingComponent />}>
              <AppRouter
                user={user}
                setIsDrawerOpen={setIsDrawerOpen}
                isDrawerOpen={isDrawerOpen}
                signOut={signOut}
              />
            </Suspense>
          </Box>
          <Toaster
            position="bottom-right"
            toastOptions={{
              duration: 5000,
              style: {
                overflowWrap: 'anywhere',
              },
            }}
          />
        </AppContextProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </BrowserRouter>
  );
};

export default App;

interface Props {
  user: SignedInUser;
  signOut: () => void;
}
