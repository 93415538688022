import { CircularProgress, Backdrop } from '@mui/material';

const AppScopeLoadingComponent: React.FC = (): JSX.Element => {
  return (
    <Backdrop sx={{ color: '#fff', zIndex: '2000' }} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default AppScopeLoadingComponent;
