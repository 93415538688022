import { createTheme } from '@mui/material/styles';

const gsTheme = createTheme({
  palette: {
    primary: {
      main: '#004CAB',
    },
    secondary: {
      main: '#F0B41C',
    },
    // mode: 'dark',
  },
  typography: {
    fontFamily: 'Source Sans Pro, sans-serif',
  },
  components: {
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: '#004CAB',
      },
    },
  },
});

export { gsTheme };
